import React, {useState, useEffect, useCallback} from "react";
import {Button, Alert} from "reactstrap";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {getConfig} from "../config";
import Loading from "../components/Loading";

const axios = require('axios').default;

function ProjectDetails(props: any) {
    const [state, setState] = useState({
        data: {},
        users: [],
        emailAddress:"",
        username: "",
        errorMessage: "",
        userPassword: ""
    })

    const {
        getAccessTokenSilently,
    } = useAuth0();

    const {audience} = getConfig();
    const getApi = useCallback(() => {
        let apiUrl = ""
        if (window.location.host === 'localhost:3000') {
            apiUrl = "http://localhost:3000"
        } else {
            apiUrl = `${audience}` as string
        }
        return apiUrl
    }, [audience])

    const handsetUserChange = (event: any) => {
        setState((current) => ({ ...current, username: event.target.value }))
    }
    const handsetEmailChange = (event: any) => {
        setState((current) => ({ ...current, emailAddress: event.target.value }))
    }

    const addUser = async () => {
        const apiUrl = getApi()
        const token = await getAccessTokenSilently()
        axios.post(`${apiUrl}/projects/${props.project}/users`, {
            email: state.emailAddress,
            username: state.username,
            role: 'ADMIN'
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((result: { data: any; }) => {
            axios.post(`${apiUrl}/projects/${props.project}/users/${state.emailAddress}/token`, {
                username: state.username,
                password: "XYZ"
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then( (result: {data: any;} ) => {
                alert("Password: " + result.data.password)
            })
            getProjectDetails()
        }).catch( (err: any) => { setState((current) => ({
            ...current, errorMessage: "Project Name must be DNS friendly: " + JSON.stringify(err.response.data.errors.json) }))
        })
    }

    const enableProject = async (enabled: boolean) => {
        const apiUrl = getApi()
        const token = await getAccessTokenSilently()
        axios.put(`${apiUrl}/projects/${props.project}`, {
            name: props.project,
            enabled: enabled
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((result: { data: any; }) => {

        })
    }

    const deleteUser = async (email: string) => {
        const apiUrl = getApi()
        const token = await getAccessTokenSilently()
        axios.delete(`${apiUrl}/projects/${props.project}/users/${email}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((result: { data: any; }) => {

        })
    }

    const getProjectDetails = useCallback(()=>{
        const myasyncfunction = async () => {
            const apiUrl = getApi()
            const token = await getAccessTokenSilently()
            axios.get(`${apiUrl}/projects/${props.project}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((result: { data: any; }) => {
                setState((current) => ({ ...current, data: result.data }))
            })

            axios.get(`${apiUrl}/projects/${props.project}/users`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((result: { data: any; }) => {
                setState((current) => ({ ...current, users: result.data }))
            })
        }
        myasyncfunction()
    },[getApi, getAccessTokenSilently, props.project])

    useEffect(() =>  {
        setState((current) => ({ ...current, data: {} }))
        getProjectDetails();
    }, [getProjectDetails, getApi, getAccessTokenSilently, props.project])

    const userList = state.users.map( (user: any) => {
        return <div>
            {user.email}:{user.username} {user.role}  {user.role_created_at}
            <Button
                color="primary"
                className="mx-2"
                onClick={() => {deleteUser(user.email)} }
            >
                Delete User
            </Button>
        </div>
    })

    return props.project ?
        <div>
            SELECTED PROJECT DETAILS {props.project}

            {state.errorMessage &&
                <h3 className="error"> { state.errorMessage } </h3>}

            <div>
                <input type="text" value={state.emailAddress} onChange={handsetEmailChange} placeholder="Email Address" />
                <input type="text" value={state.username} onChange={handsetUserChange} placeholder="Ghidra Project Username" />
                <Button
                    color="primary"
                    className="mx-2"
                    onClick={() => {addUser()} }
                    disabled={!audience}
                >
                    Add User
                </Button>

                <Button
                    color="primary"
                    className="mx-2"
                    onClick={() => {enableProject(true)} }
                    disabled={!audience}
                >
                    Enable Project
                </Button>

                <Button
                    color="primary"
                    className="mx-2"
                    onClick={() => {enableProject(false)} }
                    disabled={!audience}
                >
                    Disable Project
                </Button>

            </div>
            <pre>{JSON.stringify(state.data, null, 2)}</pre>

            <pre>{userList}</pre>
        </div>
        :
        <div>Select a Project to view details</div>
}

export const ExternalApiComponent = () => {
    const {audience} = getConfig();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: "",
        error: "",
        errorMessage: "",
        name: "",
        projects: [],
        selectedProject: ""
    });

    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();

    const getApi = useCallback(() => {
        let apiUrl = ""
        if (window.location.host === 'localhost:3000') {
            apiUrl = "http://localhost:3000"
        } else {
            apiUrl = `${audience}` as string
        }
        return apiUrl
    }, [audience])

    useEffect(() => {
        const apiUrl = getApi()
        getAccessTokenSilently().then((token) => {
            axios.get(`${apiUrl}/projects/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((result: { data: any; }) => {
                setState((current) => ({ ...current, projects: result.data }))
            })
        });

    }, [getApi, getAccessTokenSilently]);

    const handleConsent = async () => {
        try {
            await getAccessTokenWithPopup();
            setState({
                ...state,
                error: "",
            });
        } catch (error) {
            setState({
                ...state,
                error: JSON.stringify(error)
            });
        }

        await callApi();
    };

    const handleLoginAgain = async () => {
        try {
            await loginWithPopup();
            setState({
                ...state,
                error: "",
            });
        } catch (error) {
            setState({
                ...state,
                error: JSON.stringify(error),
            });
        }

        await callApi();
    };

    const deleteProject = async(projectName: string) => {
        let apiUrl = getApi()
        const token = await getAccessTokenSilently();
        await axios.delete(`${apiUrl}/projects/${projectName}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })

        axios.get(`${apiUrl}/projects/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((result: { data: any; }) => {
            setState((current) => ({ ...current, projects: result.data }))
        })
    }

    const selectProject = async(projectName: string) => {
        setState((current) => ({ ...current, selectedProject: projectName }))
    }

    const callApi = async () => {
        let apiUrl = getApi();

        const token = await getAccessTokenSilently();
        //const [{ data, loading, error }, refetch] = useAxios({
        //    url: apiUrl,
        //    headers: {'Authorization': `bearer ${accessToken}`}
        //})

        await axios.post(`${apiUrl}/projects/`, {
            name: state.name
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).catch( (err: any) => { setState((current) => ({
            ...current, errorMessage: "Project Name must be DNS friendly: " + err.response.data.errors.json.name[0] }))
        })

        axios.get(`${apiUrl}/projects/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((result: { data: any; }) => {
            setState((current) => ({ ...current, projects: result.data }))
        })

        /* try {
            const token = await getAccessTokenSilently();

            //const response = await fetch(`${audience}/projects`, {
            const response = await fetch(`http://localhost:3000/projects/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            setState({
                ...state,
                showResult: true,
                apiMessage: responseData,
            });
        } catch (error) {
            setState({
                ...state,
                error: JSON.stringify(error),
            });
        } */
    };

    const handle = (e: any, fn: any) => {
        e.preventDefault();
        fn();
    };

    const handleChange = (event: any) => {
        setState((current) => ({ ...current, name: event.target.value }))
    }

    const projectList = state.projects.sort((a:{name:string},b:{name:string}) => {return a.name.localeCompare(b.name)}).map((project: any) => {
        return <div className="m-2" key={project.name}>
            Project: <strong>{project.name}</strong> { project.creator_email}
            <Button
                color="warning"
                className="mx-2"
                onClick={() => {deleteProject(project.name)} }
                disabled={!audience}
            >
                Delete
            </Button>

            <Button
                color="primary"
                className="mx-2"
                onClick={() => {selectProject(project.name)} }
                disabled={!audience}
            >
                View
            </Button>
        </div>
    })


    return (
        <>
            <div className="mb-5">
                {state.error === "consent_required" && (
                    <Alert color="warning">
                        You need to{" "}
                        <a
                            href="#/"
                            className="alert-link"
                            onClick={(e) => handle(e, handleConsent)}
                        >
                            consent to get access to users api
                        </a>
                    </Alert>
                )}

                {state.error === "login_required" && (
                    <Alert color="warning">
                        You need to{" "}
                        <a
                            href="#/"
                            className="alert-link"
                            onClick={(e) => handle(e, handleLoginAgain)}
                        >
                            log in again
                        </a>
                    </Alert>
                )}

                <h1>External API</h1>
                <p className="lead">
                    Ping an external API by clicking the button below.
                </p>

                {state.errorMessage &&
                    <h3 className="error"> { state.errorMessage } </h3>}

                <p>
                    This will call a local API on port 3001 that would have been started
                    if you run <code>npm run dev</code>. An access token is sent as part
                    of the request's `Authorization` header and the API will validate it
                    using the API's audience value.
                </p>

                {!audience && (
                    <Alert color="warning">
                        <p>
                            You can't call the API at the moment because your application does
                            not have any configuration for <code>audience</code>, or it is
                            using the default value of <code>YOUR_API_IDENTIFIER</code>. You
                            might get this default value if you used the "Download Sample"
                            feature of{" "}
                            <a href="https://auth0.com/docs/quickstart/spa/react">
                                the quickstart guide
                            </a>
                            , but have not set an API up in your Auth0 Tenant. You can find
                            out more information on{" "}
                            <a href="https://auth0.com/docs/api">setting up APIs</a> in the
                            Auth0 Docs.
                        </p>
                        <p>
                            The audience is the identifier of the API that you want to call
                            (see{" "}
                            <a href="https://auth0.com/docs/get-started/dashboard/tenant-settings#api-authorization-settings">
                                API Authorization Settings
                            </a>{" "}
                            for more info).
                        </p>

                        <p>
                            In this sample, you can configure the audience in a couple of
                            ways:
                        </p>
                        <ul>
                            <li>
                                in the <code>src/index.js</code> file
                            </li>
                            <li>
                                by specifying it in the <code>auth_config.json</code> file (see
                                the <code>auth_config.json.example</code> file for an example of
                                where it should go)
                            </li>
                        </ul>
                        <p>
                            Once you have configured the value for <code>audience</code>,
                            please restart the app and try to use the "Ping API" button below.
                        </p>
                    </Alert>
                )}

                <input type="text" value={state.name} onChange={handleChange} />

                <Button
                    color="primary"
                    className="mx-2"
                    onClick={callApi}
                    disabled={!audience}
                >
                    Create Project
                </Button>

                {projectList}

                <ProjectDetails project={state.selectedProject}></ProjectDetails>
            </div>

            <div className="result-block-container">
                {state.showResult && (
                    <div className="result-block" data-testid="api-result">
                        <h6 className="muted">Result</h6>
                        <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
                    </div>
                )}
            </div>
        </>
    );
};

export default withAuthenticationRequired(ExternalApiComponent, {
    onRedirecting: () => <Loading/>,
});
