import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import LoginButton from "./components/LoginButton";
import Profile from "./components/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./components/LogoutButton";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import NavBar from "./components/NavBar";
import {Container} from "reactstrap";
import Loading from "./components/Loading";
initFontAwesome();

export default function App() {
    const { isLoading, error } = useAuth0();

    if (error) {
        return <div>
            <h2>Error</h2>
            <div>Oops... {error.message}</div>
            <div>
              <LoginButton></LoginButton>
              <LogoutButton></LogoutButton>
            </div>
        </div>;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Router >
            <div id="app" className="d-flex flex-column h-100">
                <NavBar />
                <Container className="flex-grow-1 mt-5">
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/external-api" component={ExternalApi} />
                    </Switch>
                </Container>
            </div>
        </Router>
    );
};
function Home() {
    return <div>
        <h2>Home</h2>
        <LoginButton></LoginButton>
        <LogoutButton></LogoutButton>
    </div>;
}
