
interface Auth0Config {
    domain: string
    clientId: string
    audience?: string
}

let auth0Config : Auth0Config

export async function initAuth0Config() {
    const configResult = await fetch("/auth_config.json");
    const configJson = await configResult.json()
    const audience =
        configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
            ? configJson.audience
            : null;

    auth0Config = {
        domain: configJson.domain,
        clientId: configJson.clientId,
        ...(audience ? { audience } : null),
    };

}

export function getConfig() {
    // Configure the audience here. By default, it will take whatever is in the config
    // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
    // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
    // don't have an API).
    // If this resolves to `null`, the API page changes to show some helpful info about what to do
    // with the audience.
    return auth0Config;
}
